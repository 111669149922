<template>
  <DataTable
    :value="logs"
    class="p-datatable-customers"
    dataKey="id"
    :removableSort="true"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :paginator="true"
    :rows="50"
    v-model:filters="filter"
    :globalFilterFields="['email']"
    :loading="loading"
  >
    <template #loading>
      Loading records, please wait...
    </template>
    <template #header>
      <div class="fw-header">
        <div>
          <h6 class="mt-3 ml-2">
            <span class="p-menuitem-icon fas fa-stream" aria-hidden="true"></span> Access Logs
          </h6>
        </div>

        <div>
          <div class="w-full mt-1">
            <span class="p-input-icon-left w-full">
              <i class="fa fa-search" />
              <InputText
                v-model="filter['global'].value"
                placeholder="Search"
                :class="'fullWidthInput'"
                type="search"
              />
            </span>
          </div>
        </div>
        <div>
          <button
            class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
            type="button"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>

    <Column field="timeStamp" header="Time Stamp" :sortable="true"></Column>
    <Column field="email" header="Email" :sortable="true" class="break"></Column>
    <Column field="ip" header="IP"></Column>
    <Column field="country" header="Country" :sortable="true"></Column>
    <Column field="state" header="State" :sortable="true"></Column>
    <Column field="city" header="City" :sortable="true"></Column>
    <Column field="os" header="OS" :sortable="true"></Column>
    <Column field="status" header="Status" :sortable="true"></Column>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
export default {
  components: {},
  data() {
    return {
      logs: null,
      loading: false,
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    };
  },
  created() {},
  mounted() {
    this.loading = true;
    this.axios.get("https://api.ftgs.io/logs").then(response => {
      this.logs = response.data;
      this.loading = false;
    });
  },
  methods: {}
};
</script>
